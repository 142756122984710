import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement);

const createChart = (data, title, backgroundColors, borderColors) => ({
  data: {
    labels: data.labels,
    datasets: [
      {
        label: title,
        data: data.values,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 2,
      },
    ],
  },
  options: {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: 18,
          weight: 'bold',
        },
        padding: {
          bottom: 10,
        },
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
        bodyFont: {
          size: 14,
          weight: 'bold',
        },
        titleFont: {
          size: 16,
          weight: 'bold',
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 2,
      },
    },
    layout: {
      padding: {
        top: 20,   
        bottom: 20, 
        left: 20, 
        right: 20, 
      },
    },
    animation: {
      animateRotate: true,
      animateScale: true,
      duration: 1500,
      easing: 'easeOutQuad',
    },
    maintainAspectRatio: false,
  },
});

const PieChart = ({ data }) => (
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px', position: 'relative', width: '500px', height: '500px' }}>
    <div className="chart-wrapper" style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
      <Pie data={data.data} options={data.options} />
    </div>
  </div>
);


const PieCharts = () => {
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <PieChart
        data={createChart(
          {
            labels: ['Knits', 'Wovens'],
            values: [55, 45],
          },
          'Knits vs. Wovens',
          [
            'rgba(91,155,213,255)',
            'rgba(112,173,71,255)'
          ],
          [
            'rgba(91,155,213,255)',
            'rgba(112,173,71,255)'
          ]
        )}
      />
      <PieChart
        data={createChart(
          {
            labels: ['Menswear', 'Womenswear', 'Uniform & Accessories', 'Kidswear'],
            values: [26, 23, 24, 27],
          },
          'Product Mix',
          [
            'rgba(67,103,42,255)',
            'rgba(112,173,71,255)',
            'rgba(255,192,0,255)',
            'rgba(91,155,213,255)'
          ],
          [
            'rgba(67,103,42,255)',
            'rgba(112,173,71,255)',
            'rgba(255,192,0,255)',
            'rgba(91,155,213,255)'
          ]
        )}
      />
      <PieChart
        data={createChart(
          {
            labels: ['Linen & Blends', 'Wool/Silk & Blends', 'Novelty Blends', 'Sustainable Blends', 'Nylon & Blends', 'Polyester & Blends', 'Cotton Blends'],
            values: [5, 5, 8, 16, 12, 24, 30],
          },
          'Material Matrix',
          [
            'rgba(23,112,135,255)',
            'rgba(95,148,212,255)',
            'rgba(96,125,35,255)',
            'rgba(29,73,126,255)',
            'rgba(51,180,215,255)',
            'rgba(157,199,70,255)',
            'rgba(59,124,201,255)'
          ],
          [
            'rgba(23,112,135,255)',
            'rgba(95,148,212,255)',
            'rgba(96,125,35,255)',
            'rgba(29,73,126,255)',
            'rgba(51,180,215,255)',
            'rgba(157,199,70,255)',
            'rgba(59,124,201,255)'
          ]
        )}
      />
      <PieChart
        data={createChart(
          {
            labels: ['Tops, Tees and Dresses', 'Bottoms', 'Outerwear', 'Activewear'],
            values: [32, 24, 24, 20],
          },
          'Product Type Mix',
          [
            'rgba(198,214,234,255)',
            'rgba(151,181,216,255)',
            'rgba(74,121,178,255)',
            'rgba(62,101,148,255)'
          ],
          [
            'rgba(198,214,234,255)',
            'rgba(151,181,216,255)',
            'rgba(74,121,178,255)',
            'rgba(62,101,148,255)'
          ]
        )}
      />
    </div>
  );
};

export default PieCharts;
