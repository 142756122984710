import React, { useState } from 'react';
import './navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (

      <div className="dropdown">
        <button className="dropdown-button" onClick={toggleDropdown}>
          ☰
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            <a href="#top" onClick={(e) => setIsOpen(false)}>Home</a>
            <a href="#concepttocollection" onClick={(e) => setIsOpen(false)}>Concept to Collection</a>
            <a href="#businessmodels" onClick={(e) => setIsOpen(false)}>Business Models</a>
            <a href="#productoverview" onClick={(e) => setIsOpen(false)}>Product Overview</a>
            <a href="#productmatrix" onClick={(e) => setIsOpen(false)}>Product Matrix</a>
            <a href="#performance" onClick={(e) => setIsOpen(false)}>Performance Dashboard</a>
            <a href="#certifications" onClick={(e) => setIsOpen(false)}>Certifications & Practices</a>
            <a href="#ourteam" onClick={(e) => setIsOpen(false)}>Our Team</a>
            <a href="#businessmap" onClick={(e) => setIsOpen(false)}>Business Map</a>
            <a href="#brandmap" onClick={(e) => setIsOpen(false)}>Brand Map</a>
          </div>
        )}
      </div>

  );
};

export default Navbar;
